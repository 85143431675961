body {
  font-family: Arial, Helvetica, Helvetica Neue, serif;
  background-color: #fff;
}

.exportbtn {
   float: right;
}
.close-btn {
   float: right;
   margin-top: 6px;
   font-size: 15px;
   padding: 3px;
   min-width: 20px;
   min-height: 20px;
   margin-left: 20px;
   border-radius: 100%;
   -webkit-app-region: no-drag;
}
.mainicon {
   font-size: 50px;
}
.navbg {
   position: fixed;
   -webkit-app-region: drag;
   z-index: 200;
   top: 0px;
   left: 0px;
   padding-top: 0px;
   margin-bottom: 50px;
   background-color: #B11116;
   box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
   width: 100%;
   color: white;
}
a {
   text-decoration: none;
   color: rgba(0, 0, 0, 0.87);
}
.navbg a {
   text-decoration: none;
   color: white;
}
.navbg a:hover {
   text-decoration: none;
   color: #7a7c7c;
}
.navlogo {
   position: relative;
   font-size: 30px;
   color: red;
   font-family: Brush Script MT, Brush Script Std, cursive;
   -webkit-app-region: no-drag;
}
.navlink {
   position: relative;
   font-size: 30px;
   padding-left: 30px;
   color: red;
   font-family: Brush Script MT, Brush Script Std, cursive;
   -webkit-app-region: no-drag;
}
.cntcont {
  position: relative;
  top: 175px;
  text-align: center;
}
.titlebanner {
   position: relative;
   height: 30px;
   padding-top: 12px;
   padding-left: 20px;
   padding-bottom: 20px;
   padding-right: 20px;
   font-size: 24px;
   font-family: Noto Sans, sans-serif;
}
.container-indexer {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
}
.add-row {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
  border-radius: 2px;
  border-top: 3px solid #B11116;
  background-color: rgb(237, 237, 237);
  padding: 30px 18px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);

}

.add-row input {
  position: relative;
  float: left;
  margin-left: 15px;
  width: 80px;
  overflow: hidden;
}
.add-row-title {
  min-width: 250px;
}
.add-row-description {
  min-width: 100%;
}
.add-row button {
   max-width: 100px;
   margin-left: 30px;
}

/* */

header-old {
  color: #fff;
  background-color: #103D5D;
  padding: 33px 0 25px 15px;
}



.fa {
  font-size: 22px;
  transform: translateY(50deg);
}

header i {
  vertical-align: middle;
  margin-left: 12px;
}

.tool-container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding-top: 100px;
}

.add-item {
  position: relative;
  min-height: 100px;
  max-height: 250px;
  margin-left: 30px;
  border-radius: 2px;
  border-top: 3px solid #B11116;
  background-color: rgb(237, 237, 237);
  padding: 18px 30px 90px 18px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  width: 340px;
  visibility: visible;
}
.display-item {
  padding:0px;
  min-width: 350px;
  background-color:#fff;
  color: #000;
  margin-bottom: 0px;
}
.wrapper{
  padding:0px;
}
.wrapper-body{
  padding:15px;
}
.display-item button {
  display: block;
  position:relative;
  bottom:0;
}

.display-item img{
  width:100%;
  height:auto;
}
.item-barrier {
  margin-bottom: 15px;
}

input {
  font-size: 22px;
  color: #000;
  padding: 18px 22px;
  font-size: 16px;
  margin-bottom: 17px;
  border: 0;
  display: block;
  width: 100%;
}
textarea {
  font-size: 22px;
  color: #000;
  padding: 18px 22px;
  font-size: 16px;
  margin-bottom: 17px;
  border: 0;
  display: block;
  width: 500px;
}

.wrapper ul {
  display: flex;
  flex-wrap: wrap;
  padding:0px;
  margin:0px;
}

.wrapper li {
  width: 100%;
  list-style-type: none;
}

h2 {
  padding: 0px;
  margin: 0px;
  font-weight: 400;
}

li h3 {
  background-color: #B11116;
  margin: 0px;
  color: #fff;
  font-weight: 300;
  padding: 15px;
}




h1 {
   font-family: Arial, Helvetica, Helvetica Neue, serif;
   margin-left: 30px;
}

/* */
.Modal {
  margin:auto;
  margin-top:50vh;
  transform:translateY(-50%);
  max-width: 500px;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
}
.Modal button {

  float: right;
}
.user-profile {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 75px;
  height: 75px;
  z-index: 201;
}
.user-profile img {
  border: 3px solid white;
  border-radius: 50%;
  max-width: 100%;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-app-region: no-drag;
}

/*EXPORT*/
.dexter {
   font-size: 13px;
}
.dex-heading {
   font-family: calibri light;
   border-bottom:1px solid #CCC;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 45px;
   color: black;
}
.indexbox {
   position: relative;
   padding-top: 20px;
   padding-left: 30px;
   border: 2px solid #CCC;
   border-radius: 2%;
}
.topic {

   color: #1667ff;
}
.content {

}

.red-border{
  border-top:3px solid #B11116 !important;
  background-color:#dcdcdc;
  margin-bottom: -5px;
}

input{
  background-color:white!important;
  border-radius:5px;
}

.scrollDown:hover{
  opacity:1.0;
}
.scrollDown{
  opacity:0.25;
  z-index: 1000;
  transform:rotate(180deg);
  position:fixed!important;
  bottom:10px;
  right:10px;
}

.scrollUp{
  z-index: 1000;
  position:fixed!important;
  top:75px;
  right:10px;
  opacity:0.25;
}
.scrollUp:hover{
  opacity:1.0;
}

.voltaire-action{
  background-color: #FA6900;
}
